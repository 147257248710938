/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default function useDataRequestorState() {
  const dataRequestorStoreRepository = useService('dataRequestorStoreRepository')

  const hasDataRequest = computed(() => dataRequestorStoreRepository.hasDataRequest())

  const getDataRequest = (): ComputedRef<DataRequestProjection> => {
    return computed(() => dataRequestorStoreRepository.getDataRequest())
  }

  const isAuthenticated = computed(() => dataRequestorStoreRepository.isAuthenticated())
  const isAuthenticationProcessInitiated = computed(() =>
    dataRequestorStoreRepository.isAuthenticationProcessInitiated(),
  )
  const authenticationProcessInitiatedFor = () =>
    dataRequestorStoreRepository.authenticationProcessInitiatedFor()

  return {
    hasDataRequest,
    getDataRequest,
    isAuthenticated,
    isAuthenticationProcessInitiated,
    authenticationProcessInitiatedFor,
  }
}
